﻿.breadcrumb {
    &__group {
        padding: 0;
        list-style-type: none;

        & li {
            border-bottom: 4px solid transparent;
        }
    }

    &__item {
        padding: 4px 12px;
        text-transform: uppercase;
        font-family: "Roboto","Arial",sans-serif;
        border-bottom: 4px solid transparent;
        line-height: 1.5;
        letter-spacing: 0.1em;
        font-weight: 400;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }

        &.is-active {
            border-bottom: 4px solid #a7c5c3;
        }

        & a {
            &:hover {
                text-decoration: none;
                color: #a7c5c3;
            }
        }

        &:hover {
            color: #a7c5c3;
        }
    }

    &__slash {
        padding: 4px 8px;
        pointer-events: none;
    }
}

﻿/*These styles ensure that there will be no overflow of member content outside of the MemberDisplayBlock*/
.MemberTable {
    width: 375px;
    table-layout: fixed;
    word-wrap: break-word;
}

.MemberTD {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

﻿/* Searchbox in Header */
.searchbox {
    position: absolute;
    right: 82px;
    top: 0;
    height: 80px;
    transition-duration: 0.4s;
    transition-property: width;
    width: 80px;

    &__product-list {
        max-height: 45vh;
        overflow-y: auto;

        ul {
            padding-left: 0px;
        }
    }

    &__row {
        width: 100%;
        padding: 12px 20px;
        display: flex;

        &:not(last-child) {
            border-bottom: 1px solid #eee;
        }
    }

    &__heading {
        text-transform: uppercase;
        background: #F9F9F9;
        padding: 12px 20px;
    }

    &__product {
        margin-left: 10px;
        flex: 1;

        &-price {
            font-weight: 500;
            display: inline-block;
            margin-top: 10px;
        }
    }

    &__product-img {
        width: 60px;
        display: inline-block;
    }

    &__product-name {
        font-size: 0.875rem;
        line-height: 1rem;
        color: black;
        display: block;

        &:hover {
            color: black;
        }
    }

    &__notfound {
        font-style: italic;
        margin-bottom: 0;
    }

    input {
        width: 100%;
        height: 100%;
        border: 0;
        border-left: 1px solid #eee;
        padding-left: 40px;
        padding-right: 40px;
        z-index: 1;

        &:focus {
            outline: none;
        }
    }

    .icon-left {
        position: absolute;
        left: 8px;
        top: 27px;
        z-index: 1000;
    }

    .icon-right {
        position: absolute;
        right: 8px;
        top: 27px;
        cursor: pointer;
    }
}

.searchbox-popover {
    @media screen and (min-width: 992px) {
        width: 322px;
    }

    width: calc(100% + 80px);
    left: 0;
    top: 0;
    position: absolute;
    min-height: 80px;
    box-shadow: 0 3px 4px rgba(0,0,0,0.25);
    z-index: 9999;
    background: #fff;
    font-size: $text-font-size;
    line-height: 16px;

    div {
        min-height: 80px;
    }
}

/* Searchbox widget */
.search-box {
    border: 1px solid #ddd;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > input[type=text] {
        font-size: 16px;
        padding: 12px 20px;
        border: 0;
        flex-grow: 1;

        &:focus {
            border: 0;
            outline: 0;
        }
    }

    & > a {
        height: 100%;
        padding: 15px 30px;
        flex-grow: 0;
        border-left: 1px solid #ddd;

        &:hover {
            background-color: lightgrey;
            color: white;
        }
    }
}

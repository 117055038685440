﻿.block.vimeoblock {
    float: none;

    .media {
        display: block;
    }

    p {
        margin-bottom: 1.2rem;
    }
}

//Vimeo Video Block
.vimeo-container {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
}

.vimeo-container iframe,
.vimeo-container object,
.vimeo-container embed,
.vimeo-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


﻿.table {
    .thead-dark {
        th {
            background-color: black;
            border-color: black;
            text-transform: uppercase;
        }
    }

    td {
        vertical-align: middle;
    }
}

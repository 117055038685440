﻿.login {
    &__logo {
        max-height: 80px;
    }

    &__background {
        background-color: #ececec;
    }

    &__group {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }

    &__form {
        padding: 20px 40px 20px 40px;
        background-color: white;
        box-shadow: 0 0 15px grey;

        @media screen and (min-width: 1492px) {
            width: 30%;
        }

        @media screen and (max-width: 1491px) {
            width: 35%;
        }

        @media screen and (max-width: 1200px) {
            width: 50%;
        }

        @media screen and (max-width: 767px) {
            width: 80%;
        }
    }

    &__row {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;

        &--bottom {
            width: 100%;
            display: flex;
            align-items: center;
            padding: 15px;
            justify-content: space-between;
        }
    }

    &__input {
        border: 0;
        border-bottom: 1px solid grey;
        width: 100%;
        height: 40px;
    }
}

﻿.order-detail {
    &__note-block {
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #eeeeee;
    }

    &__item {
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #eeeeee;
    }
}

.checkbox {
    user-select: none;
    position: relative;
    cursor: pointer;
    padding-left: 35px;
    text-align: center;

    & {
        input[type="checkbox"], input[type="radio"] {
            display: none;
        }
    }
}

/* Create a custom checkbox */
.radio,
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid black;
}

/* When the checkbox is checked, add a black background */
.checkbox input:checked ~ .radio,
.checkbox input:checked ~ .checkmark {
    background-color: black;
}

.checkbox input[type="radio"] ~ .radio,
.checkbox input[type="radio"] ~ .checkmark {
    border-radius: 50%;
}

/* Create the checkmark/indicator (hidden when not checked) */
.radio:after,
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox input:checked ~ .radio:after,
.checkbox input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox .radio:after {
    left: 7.35px;
    top: 7.7px;
    width: 8px;
    height: 8px;
    background: white;
    border-radius: 50%;
    /*border: 1px solid black;*/
}

.checkbox .checkmark:after {
    left: 10px;
    top: 6px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.hamburger-menu {
    &:hover {
        cursor: pointer;
    }

    &__line {
        @include media-breakpoint-down(sm) {
            width: 35px;
            height: 3px;
        }

        width: 40px;
        height: 3px;
        background-color: black;
        display: block;
        margin: 8px auto;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }

    &.is-active .hamburger-menu__line:nth-child(2) {
        opacity: 0;
    }

    &.is-active .hamburger-menu__line:nth-child(1) {
        -webkit-transform: translateY(13px) rotate(45deg);
        -ms-transform: translateY(13px) rotate(45deg);
        -o-transform: translateY(13px) rotate(45deg);
        transform: translateY(13px) rotate(45deg);
    }

    &.is-active .hamburger-menu__line:nth-child(3) {
        -webkit-transform: translateY(-13px) rotate(-45deg);
        -ms-transform: translateY(-13px) rotate(-45deg);
        -o-transform: translateY(-13px) rotate(-45deg);
        transform: translateY(-13px) rotate(-45deg);
    }
}

﻿.icon-button {
    display: inline-block;
    background-color: transparent;
    border: 1px solid transparent;
    border-color: #000;
    padding: 3px;
    color: #555;
    border: 0;

    &:hover {
        background-color: transparent;
        color: #555;
        opacity: 0.7;
    }

    &:disabled {
        color: #fff;
        background-color: #7f7f7f;
        border-color: #7f7f7f;
    }
}

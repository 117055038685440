﻿.dropdown {
    outline: 1px solid #eee;
    outline-offset: -1px;
    user-select: none;

    &__group {
        display: none;
        width: 100%;
        position: absolute;
        border-left: 1px solid #eeeeee;
        border-right: 1px solid #eeeeee;
        border-top: 1px solid #eeeeee;
        background-color: white;
        z-index: 1;
        max-height: 300px;
        overflow-y: auto;
        list-style: none;
        padding: 0;
    }

    &__item {
        width: 100%;
        padding: 15px;
        border-bottom: 1px solid #eeeeee;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
            background-color: #f5f5f5;
        }

        &--none-padding {
            padding: 0;
        }

        &-label {
            width: 100%;
            height: 100%;
            text-align: start;
            padding: 15px;
            margin: 0;
            padding-left: 50px;

            .checkmark {
                top: 15px;
                left: 15px;
            }
        }
    }

    &__selected {
        width: 100%;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
    }
}

/* Clear inner floats */
.clearfix::after {
    clear: both;
    content: "";
    display: table;
}

body {
    overflow-x: hidden;
}

video {
    width: 100%;
    height: auto;
}

/* Override bootstrap container for 1200px and up */
.container {
    @include media-breakpoint-up(xl) {
        max-width: 1440px;
    }
}

/* Make all blocks float to the left and clear duplicate paddings caused by nested Bootstrap's column */
div.block {
    display: inline-block;
    vertical-align: top;
    padding-left: 0;
    padding-right: 0;
}

.display-none {
    display: none;
}

hr {
    color: #C4C4C4;
}

.filters-tag {
    position: relative;
    border-radius: 3px;
    background-color: $color-darkgrey;
    color: black;
    font-size: 12px;
    padding: 5px 20px 5px 10px;
    margin-bottom: 0;

    &__remove {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        text-align: right;
        padding-right: 5px;
        padding-top: 2px;
    }
}

.content-filters-tag {
    position: relative;
    border-radius: 3px;
    background-color: $color-darkgrey;
    color: black;
    font-size: $text-font-size;
    padding: 5px 10px 5px 10px;
    margin-bottom: 0;
}

.hidden {
    display: none;
}

.style-list-none {
    list-style: none;
    padding: 0;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-between {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.pull-right {
    float: right;
}

.border {
    border: 1px solid #eeeeee;
}

.border-radius-none {
    border-radius: 0;
}

.link {
    text-decoration: none;
    color: #666;
    padding-right: 15px;

    &:hover {
        text-decoration: none;
        color: black;
        opacity: 0.7;
    }
}

.square-box {
    border-radius: 0;
}

.cursor-pointer {
    cursor: pointer;
}

.full-width {
    width: 100%;
}

.align-center {
    text-align: center;
}

.success {
    padding: 15px;
    background-color: #DFF0D8;
    color: #468847;
}

.error {
    padding: 15px;
    background-color: #F2DEDE;
    color: #B94A48;
}

ul.none-style-type {
    list-style-type: none;
}

.screen-width-block {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    display: block !important;

    .screen-width-wrapper {
        @include media-breakpoint-up(xl) {
            max-width: $container-max-width;
        }

        width: 100%;
        margin-right: auto;
        margin-left: auto;
    }

    .screen-width-container {
        @include media-breakpoint-up(xl) {
            max-width: $container-max-width;
        }

        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
    }
}

.editMode .screen-width-block {
    width: 98vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -49vw;
    margin-right: -49vw;
}

.sticky-top {
    position: sticky;
    top: 0;
}

.disabled {
    pointer-events: none;
    cursor: default;
    background-color: lightgray;
    border: 0;
}

.address-block {
    margin-top: 15px;
    border-top: 1px solid #eeeeee;
    padding-top: 20px;
}

a.account-link {
    text-decoration: underline;
    color: #555;

    &:hover {
        opacity: 0.7;
    }
}

.image-full-width {
    width: 100%;
    height: auto;
}

.input-block {
    padding-top: 15px;
    display: flex;
    align-items: flex-end;
    flex-basis: 50%;
    justify-content: space-between;
}

.form-square {
    border-radius: 0;
}

.tier-icon {
    width: 25px;
    height: 25px;
}

﻿.cart {
    box-shadow: 0 3px 4px rgba(0,0,0,0.25);
    width: 405px;
    z-index: 700;
    background: #fff;
    font-size: 0.875rem;
    line-height: 1rem;
    border: 0;
    border-radius: 0;
    padding: 0;

    &__items {
        max-height: 60vh;
        overflow-y: auto;
    }

    &__row {
        width: 100%;
        padding: 20px;
        display: flex;

        &:not(last-child) {
            border-bottom: 1px solid #eee;
        }
    }

    &__heading {
        text-transform: uppercase;
        margin-bottom: 0;
    }

    &__product-img {
        width: 60px;
        display: inline-block;
    }

    &__product {
        position: relative;
        margin-left: 10px;
        flex: 1;

        &-price {
            margin-top: 12px;
        }

        &-remove {
            position: absolute;
            bottom: 0;
            color: #666;
            cursor: pointer;
            display: inline-block;
        }
    }

    &__product-count {
        margin-left: 10px;
        font-weight: 500;
        white-space: nowrap;
    }

    &__footer {
        padding: 20px;
    }

    &__subtotal {
        font-weight: 500;
        float: right;
    }

    &__actions {
        padding-top: 20px;
        display: flex;
        width: 100%;

        button {
            flex: 1;
            text-transform: uppercase;
        }

        button + button {
            margin-left: 10px;
        }
    }
}

﻿.blog-item {
    justify-content: center;

    font-family: 'Montserrat', sans-serif;
    font-size: 16px;

    &__image {
        width: 100%;
    }
}

﻿.toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 767px) {
        & {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__left {
        .view-mode {
            display: flex;
            padding-top: 5px;
            padding-bottom: 5px;

            & span {
                background-color: #eeeeee;
                padding: 5px;
                margin-right: 5px;
                cursor: pointer;

                &:hover {
                    background-color: black;
                    color: white;
                }

                &.button-active {
                    background-color: black;
                    color: white;
                }
            }
        }
    }

    &__center {
        display: flex;
        align-items: center;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    &__right {
        display: flex;
        padding-top: 5px;
        padding-bottom: 5px;

        & div {
            display: flex;
            align-items: center;
        }

        .box-sort {
            width: 44px;
            height: 44px;
            background-color: #f5f5f5;
            border: 1px solid #eeeeee;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 0;

            &:hover {
                background-color: black;
                color: white !important;
                cursor: pointer;
            }
        }
    }
}

%icon-menu-style {
    position: relative;
    width: 80px;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
}

.icon-menu {
    @extend %icon-menu-style;

    &:active {
        background-color: #eee;
    }

    &--has-badge {
        .icon-menu__badge {
            position: absolute;
            top: 20px;
            right: 16px;
            display: block;
        }
    }

    &-description {
        @extend %icon-menu-style;
        font-size: 14px;

        &--has-badge {
            .icon-menu__badge {
                position: absolute;
                top: 10px;
                right: 16px;
                display: block;
            }
        }
    }

    &__badge {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: black;
        font-size: 10px;
        font-weight: 700;
        color: white;
        box-sizing: border-box;
        text-align: center;
        vertical-align: middle;
        line-height: 18px;
        display: none;
    }
}

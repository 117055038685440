﻿.easy-autocomplete {
    max-width: 100%;
    position: relative;
}

.searchbox .easy-autocomplete { 
    width: 100%;
    height: 100%;
}

.easy-autocomplete-container {
    position: absolute;
    min-width: 100%;
    width: auto;
    z-index: 1;
    background-color: white;

    & ul {
        width: auto;
        border: 1px solid #eeeeee;
        list-style-type: none;
        padding: 0;

        & li {
            width: auto;
            padding: 5px;
            border-bottom: 1px solid #eeeeee;
            cursor: pointer;

            &:last-child {
                border-bottom: 0;
            }

            &:hover {
                background-color: #e5e5e5;
            }
        }

        @media screen and (min-width: 767px) {
            & li {
                min-width: 200px;
            }
        }
    }

    .eac-item {
        width: auto;

        & img:first-child {
            margin-right: 5px;
        }
    }

    .eac-icon {
        max-width: 30%;
        max-height: 60px;
    }
}

.eac-fullwidth {
    width: 100%;
}
